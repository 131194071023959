.topBar-appBar{
    background: white;
    color: white;
    box-shadow: 0px 0px 0px 0px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(202, 202, 202, 0.38);
}

.topBar-avatar{
    width: 30px;
    height: 30px;
}

.MuiMenu-root .MuiMenu-paper{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    border-radius: 8px;
}

.topBar-dropdown-text:hover{
    background-color: transparent;
    color: var(--color-primary-dark);
}


.topBar-button{
    border: none;
    align-items: center;
    color: rgb(128, 128, 128);
}

.topBar-button:hover{
    background-color: transparent;
    border: 0;
    color: var(--color-primary-dark)
}