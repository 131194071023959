.survey-card, .survey-card-selected {
    width: 29%;
    height: 96%;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s, border 0.2s;
}

.survey-card {
    background-color: #ffffff;
}

.survey-card-selected {
    background-color: var(--color-primary-fade);
    border: 2px solid var(--color-primary-main);
    box-sizing: border-box;
}
