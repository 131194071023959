.history-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.history-no-data-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
}

.history-image {
  width: 24%;
  max-width: 100%;
}

.history-left-box {  
  display: flex;
  flex-direction: column;
  width: 32%;
  max-width: 800px;
  margin-left: 2.5%;
  margin-top: 1%;
  margin-right: 1.5%;
}

.history-left-card {
  display: flex;
  flex: 1;
  flex-direction: column; 
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); 
  padding: 1%;
  margin-top: 4%;
  margin-bottom: 2%;
}

.history-score-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 3%;
  margin-top: 6%;
}

.history-radar-container { 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-bottom: 1%;

  font-size: 0.8rem;
  font-weight: bold;
  color: #444;
  line-height: 1.4;
  font-family: 'Roboto', sans-serif;
}

.history-des-text{  
  display: flex;
  width: 32%;
  text-align: center;
  margin-bottom: 0.5%;
}

.history-right-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 1.7%;
  margin-right: 1.8%;
  margin-top: 0.6%;
}

.history-interviews-table {
  flex: 1;
}

.history-interview-row {
  cursor: pointer;
}

.history-subtitle-text-history {
  margin-top: 2%; 
  margin-bottom: 1.5%;
  margin-left: 1.8%;
}

.history-score-value {
  margin-bottom: 1%;
  font-size: 3rem;
  font-family: Verdana;
}

.history-score-suffix {
  font-size: 1.9rem;
}

.no-history-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
}