.newinterview-card-round, .newinterview-card-round-selected {
  width: 22%;
  height: 92%;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.2s, border 0.2s;
}

.newinterview-card-round {
  background-color: #ffffff;
}

.newinterview-card-round-selected {
  background-color: var(--color-primary-fade);
  border: 2px solid var(--color-primary-main);
  box-sizing: border-box;
}


.newinterview-centralize {
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;   
  justify-content: center;      
}

.newinterview-resume-subtitle {
  display: flex;
  width: 80%;
  align-items: center;
  text-align: center;
  margin-bottom: 6%;
}


.newinterview-fullwidth {
  width: 100%;
}