
.dashboard {
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;   
    justify-content: center;  
  }

.dashboard-upper-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 545px;
  }

  .dashboard-lower-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

.dashboard-lower-box {
    display: flex;
    flex-direction: column;
    width: 85%;
  }
  
  .dashboard-left-box {  
    display: flex;
    flex-direction: column;
    width: 55%;
    /* max-width: 800px; */
    margin-top: 1%;
    margin-right: 1.5%;
  }
  
  .dashboard-left-card {
    display: flex;
    flex: 1;
    flex-direction: column; 
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); 
    padding: 1%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100px;
    overflow-y: hidden;
  }


  .dashboard-right-box {  
    display: flex;
    flex-direction: column;
    width: 25%;
    /* max-width: 800px; */
    margin-left: 2.5%;
    margin-top: 1%;
    margin-right: 1.5%;
  }
  
  .dashboard-right-card {
    display: flex;
    flex: 1;
    flex-direction: column; 
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); 
    padding: 1%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100px;
    overflow-y: hidden;
  }
  
  .score-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
    margin-right: 3%;
    margin-top: 6%;
  }
  
  .dashboard-step-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .dashboard-radar-container { 
    margin-top: 6%;
    width: 80%;
  }
  .dashboard-line-container { 
    margin-top: 0%;
    width: 100%;
  }

  .dashboard-full-width-box {
    width: 100%;
  }

  .dashboard-card-container-round {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-around;
    width: 100%;
    height: 120px;
    margin-bottom: 20px;
  }
  
.dashboard-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 3%;
    margin-top: 2%;
    padding: 1%;
  }

  .dashboard-vertical-step-center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .dashboard-vertical-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-horizon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 15%
  }

  .dashboard-image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }

  .dashboard-nodata-image {
    width: 800px;
    height: 800px;
  }

  .dashboard-nodata-image-box {
    display: flex;
    margin-top: -30%;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    width: 100%;
    height: 65%;
  }


  .dashboard-nodata-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .test-flex{
    display: flex;
    font-size: 1.8rem;
    color: #333;
    font-family: 'Montserrat', sans-serif;

  }