.sum-score-card {
    width: 85%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
    padding: 6%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: -15%;
    height: 370px;
}

.sum-score-card-core {
    align-self: left;
    margin-bottom: 1%;

}

.sum-score-card-score-value, 
.sum-score-card-score-small-value {
    color: #333;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
}

.sum-score-card-score-value {
    font-size: 3rem;
}

.sum-score-card-score-small-value {
    font-size: 2rem;
}

.sum-score-card-description {
    font-size: 0.8rem;
    height: 100px;
    max-height: 100px;
    color: #555;
    margin: 10px 0;
    text-align: left;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
}

.sum-score-card-details {
    width: 100%;
}

.sum-score-card-detail-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5%;
    margin: 2% 0;
    font-size: 0.8rem;
}

.sum-score-card-detail-label {
    color: #777;
    text-align: left;
    flex: 1;
    padding-right: 1%;
}

.sum-score-card-detail-value {
    color: #333;
    font-size: 0.9rem;
    text-align: right;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-left: 1%;
}

.sum-score-card-feedback-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}