.dashboard-history-card {
  width: 15%;
  height: 96%;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s, border 0.2s;
}

.dashboard-history-card {
  background-color: #ffffff;
}


.dashboard-centralize {
  align-items: center;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;   
  justify-content: center;      
}