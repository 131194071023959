.dot-stepper {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  .dot-stepper .MuiMobileStepper-dots .MuiMobileStepper-dot {
    background-color: #ffffff;
    border: 0.5px solid #000000;
    margin-right: 10px;
    width: 6px;
    height: 6px;
  }
  
  .dot-stepper .MuiMobileStepper-dots .MuiMobileStepper-dotActive {
    background-color: #000000;
    width: 6px;
    height: 6px;
  }