.feedback-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.feedback-err-msg {
    width: 100%;
    text-align: center;
    margin-top: 5%;
}

.feedback-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%; 
    height: 550px;
}


/* .feedback-radar-chart-container {
    flex: 4;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-sum-score-card-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6%;
} */

@media screen and (max-width: 1000px) {
    .feedback-radar-chart-container {
        display: none !important;
    }
}

.feedback-radar-chart-container {
    position: absolute;
    width: 75%;
    left: 0;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-sum-score-card-container {
    position: absolute;
    width: 25%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6%;
}

.feedback-subtitle-text{
    margin-left: 1%;
}

.feedback-group-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-right: 3%;
    width: fit-content;
}

.feedback-flipcard-box{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}

.feedback-details-section {
    padding: 3%;
}

.feedback-detail-first-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; 
    justify-content: flex-start;
}

.feedback-detail-second-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; 
    justify-content: flex-start;
    padding-top: 3%;
}



.feedback-chat-history-title-text{
    width: 95%;
    display: flex;
    flex-direction: row;
}

.feedback-chat-history{
    width: 100%;
    overflow: hidden;
    max-height: auto;
}
.feedback-collapsed{
    max-height: none;
}

.feedback-chat-history-section {
    width: 97%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1%;
    margin-left: 1%;
}

.feedback-chat-history-table {
    width: 100%;
    padding-top: 1.5%;
    text-align: left;
    max-height: 50px;
}

.feedback-chathist-table-row{
    border-bottom: none;
    width: 80%;
}

.feedback-chathist-table-cell{
    border: none;
    word-wrap: break-word;
}

.feedback-chathist-color{
    color: var(--color-primary-main);
}