.mainScreen{
    background-color: #313131;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
}

.mainScreen-content{
    display: flex;
    justify-content: center;
    position: absolute;
    inset: 0;
    bottom: 60px;
    top: calc(2% + 40px);
}

.mainScreen-ccoff-cardMedia{
    background-color: #313131;
    border-radius: 8px;
    position:absolute;
    height: min(100%, 45vw / 16 * 9);
    aspect-ratio: 16 / 9;
}

.mainScreen-ccoff-videoOffBox{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202020;
    border-radius: 8px;
    position: absolute;
    height: min(100%, 45vw / 16 * 9);
    aspect-ratio: 16 / 9;
}

.mainScreen-ccon-cardMedia{
    background-color: #313131;
    border-radius: 8px;
    position:absolute;
    height: min(100%, 44vw / 16 * 9);
    aspect-ratio: 16 / 9;
}

.mainScreen-ccon-videoOffBox{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202020;
    border-radius: 8px;
    position: absolute;
    height: min(100%, 44vw / 16 * 9);
    aspect-ratio: 16 / 9;
}


.mainScreen-cand-videoon-ccoff,
.mainScreen-cand-videooff-ccoff{
    position: absolute;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20%;
    bottom: 30%;
    height: 50%;
    width: 45%;
    left: 51%;
}


.mainScreen-cand-videoon-ccon,
.mainScreen-cand-videooff-ccon{
    position: absolute;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 52%;
    bottom: 8%;
    height: 40%;
    width: 44%;
    left: 1%;
}


.mainScreen-intv-ccoff{
    position: absolute;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20%;
    bottom: 30%;
    height: 50%;
    width: 45%;
    right: 51%;
}

.mainScreen-intv-ccon{
    position: absolute;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8%;
    bottom: 52%;
    height: 40%;
    width: 44%;
    left: 1%;
}


.mainScreen-nameTagBox{
    position: absolute;
    background-color: #313131;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: fit-content;
    max-width: 90%;
    bottom: 1%;
    left: 0.6%;
    padding-left: 1%;
    padding-right: 1%;
    z-index: 1;
    border-radius: 4px;
}

.mainScreen-nameTagText{
    font-size: 40%;
    color: #e5e5e5;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
}

.mainScreen-cand-avatar {
    background-color: #4e3f6d;
    font-family: 'Open Sans', sans-serif;
    font-size: 100%;
    width: 80px;
    height: 80px;
    overflow: hidden;
}

.mainScreen-intv-avatar {
    background-color: #8f8f8f;
    font-family: 'Open Sans', sans-serif;
    font-size: 100%;
    width: 80px;
    height: 80px;
    overflow: hidden;
}

.mainScreen-indicator-on {
    border: 1.5px solid rgb(102, 102, 173);
}