.dashboard-record-card {
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
}

.dashboard-record-card {
    background-color: #ffffff;
}

.centered-box {
    display: flex;
    align-items: center;
    flex-direction: column;
}


.main-button-white-start-interview {
    background-color: #ffffff;
    color: #444;
    border: 1px solid #444;
    width: 150px;
    height: 55px;
    border-radius: 15px;
    text-align: center;
    font-size: 0.8em;  
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    transition: background-color 0.4s;
  }
  
  
  .main-button-white-start-interview:hover {
    background-color: var(--color-primary-fade);
  }

