.checkBox, .checkBox-selected {
    height: 18%;
    border-radius: 9px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 3%;
    transition: background-color 0.3s, border-color 0.3s;
}

.checkBox {
    background-color: #F0F0F0;
}

.checkBox-selected {
    background-color: var(--color-primary-fade);
    border: 2px solid var(--color-primary-main);
    box-sizing: border-box;
}
