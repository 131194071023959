.waitingRoom{
    position: absolute;
    display: flex;
    height: 90%;
    width: 99%;
}

.waitingRoom-video-box{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15%;
    height: 40%;
    width: 50%;
}

.waitingRoom-infoCard-box{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 11%;
    width: 40%;
    left: 52%;
}

.waitingRoom-cardMedia{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #313131;
    border-radius: 8px;
    height: min(100%, 50vw / 16 * 9);
    aspect-ratio: 16 / 9;
}

.waitingRoom-video-off-background{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202020;
    border-radius: 8px;
    height: min(100%, 50vw / 16 * 9);
    aspect-ratio: 16 / 9;
}

.waitingRoom-icon-box{
    position: absolute;
    bottom: 0;
}

.waitingRoom-icons, .waitingRoom-icons-active, .waitingRoom-icons-inactive {
    background-color: transparent;
    border-radius: 30%;
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    cursor: pointer;
    user-select: none;
  }
  
  .waitingRoom-icons-active {
    color: #ea4335;
  }

  .waitingRoom-icons{
    color: white;
  }

  .waitingRoom-icons-active:hover, 
  .waitingRoom-icons-inactive:hover, 
  .waitingRoom-icons:hover{
    background-color: transparent;
    box-shadow: none;
    transform: scale(1.2);
  }


.waitingRoom-table{
    justify-content: center; 
    border-top: 1.5px solid rgba(202, 202, 202, 0.68);
    border-bottom: 1.5px solid rgba(202, 202, 202, 0.68);
    width: 100%;
    margin-bottom: 2%;
}

.waitingRoom-tableCellTwo{
    text-align: right;
    font-weight: 550;
}