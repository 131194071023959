.newinterview-card-container-round {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-around;
    width: 80%;
    height: 390px;
    margin-top: 1%;
  }

  .newinterview-survey {
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;   
    justify-content: center;
  }

  .card-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-around;
    width: 60%;
    height: 300px;
    margin-bottom: 1.7%;
  }

  .newinterview-job-description-container {
    display: flex;
    flex-direction: column;
    max-width: 600px; 
    flex-wrap: nowrap;
    width: 43%;
    height: 340px;
    margin-bottom: 2%;
  }
  
  .newinterview-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 1%;
  }

  .newinterview-dropzone {
    width: 100%;
    min-height: 120px !important;
  }

  .newinterview-droptext {
    margin-bottom: 2% !important;
  }

  .textfield .MuiOutlinedInput-input {
    font-size: 0.9rem;
}
  .textfield .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .textfield .MuiOutlinedInput-root fieldset {
    border-color: rgba(63, 63, 63, 0.23);
  }
  .textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--color-primary-main);
  }
  .textfield .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--color-primary-main);
  }
