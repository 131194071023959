.footer{
  background: #313131; 
  width: 100%;    
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.footer-icons, .footer-icons-active, .footer-icons-inactive {
  background-color: #313131;
  border-radius: 30%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
}

.footer-icons-active {
  color: #ea4335;
}

.footer-icons-inactive {
  color: #8f8f8f;
}

.footer-icons-active:hover, 
.footer-icons-inactive:hover, 
.footer-icons:hover{
  background:#3c4043;
}


.end-button{
  color: white;
  font-size: 1.4ch;
  position: absolute;
  right: 2%;
  border-radius: 5px;
  background: #ea4335;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.end-button:hover{
  background: #ea4335;
}