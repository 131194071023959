.feedback-card-container {
    perspective: 1000px;
    width: 240px;
    height: 280px;
    display: flex;
    flex-direction: column; 
    cursor: pointer;
    padding: 6px;
    overflow: hidden;
    max-width: 100%;
}

.feedback-flippableCard {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
}

.feedback-card-container.flipped .feedback-flippableCard {
    transform: rotateY(180deg);
}

.feedback-card-front,
.feedback-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(70, 69, 69, 0.2);
}

.feedback-card-front {
    justify-content: flex-start;
}

.feedback-card-back {
    justify-content: center;
    background-color: #e9f2ff;
    transform: rotateY(180deg);
}

.feedback-card-back-description,
.feedback-card-description {
    font-size: 0.8rem;
    text-align: left;
    margin: 10px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.feedback-card-title,
.feedback-card-back-title {
    font-weight: bold;
    margin: 10px;
    text-align: left;
}

.feedback-circular-progress-container {
    display: flex;
    width: 100%;
    margin: 10px;
    align-items: center;
}

.feedback-indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.feedback-indicator {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    background-color: lightgray;
    margin-top: 5px;
    margin-left: 5px;
    border: 1px solid lightgray;
}

.feedback-indicator.active {
    background-color: gray;
}
