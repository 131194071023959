.pricingCard, .pricingCard-premium, .pricingCard-current {
    width: 320px; /* Set a fixed width */
    height: 495px; /* Set a fixed height */
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s, border 0.2s;
  }

.pricingCard {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.pricingCard-premium {
  border: 2px solid var(--color-primary-main);
  box-sizing: border-box;
}

.pricingCard-current {
  background-color: var(--color-primary-fade);
  border: 2px solid var(--color-primary-main);
  box-sizing: border-box;
}
  
  .customSwitch {
    --gestalt-switch-height: 16px;
    --gestalt-switch-width: 28px;
  }
  
  .customSwitch input[type="checkbox"]:checked + div {
    background-color: blue;
  }