.stt-joinMeeting{
    position: absolute;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    top: 54%;
    width: 25%;
    height: 40%;
    overflow: hidden;
}

.stt-text{
    padding: 0;
    text-align: center;
    color: #E5E5E5;
    font-family: 'Open Sans', sans-serif;
    font-size: larger;
    user-select: none;
}

.stt-button{
    color: black;
    background-color: #c0c0c0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: small;
    height: 35px;
    cursor: pointer;
    user-select: none;
    width: 100%;
    border-radius: 6px;
}

.stt-button-cantjoin{
    color: rgb(130, 130, 130);
    background-color: #c0c0c0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 550;
    height: 35px;
    cursor: pointer;
    user-select: none;
    width: 100%;
    border-radius: 6px;
}

.stt-agreement{
    color: #E5E5E5;
    /* font-family: 'Open Sans', sans-serif; */
    user-select: none;
}

.stt-agreement-link:visited {
    color: rgb(85, 87, 202); /* Color for visited links */
}


.stt-chatHistory-container{
    position: absolute;
    top: 8%;
    bottom: 8%;
    width: 55%;
    right: 1%;
}

.stt-chatHistory-content{
    /* color: #E5E5E5;
    font-family: 'Open Sans', sans-serif; */
    overflow: scroll;
    position: absolute;
    height: 100%;
    width: 100%;
}

.stt-flex-content{
    display: flex;
    padding-right: 5%;
    padding-left: 5%;
}

.stt-intv-avatar{
    background-color: #8f8f8f;
    font-family: 'Open Sans', sans-serif;
    font-size: xx-small;
    width: 25.2px;
    height: 25.2px;
}

.stt-cand-avatar{
    background-color: #4e3f6d;
    font-family: 'Open Sans', sans-serif;
    font-size: xx-small;
    width: 25.2px;
    height: 25.2px;
}

.stt-p{
    padding-left: 2%;
    padding-right: 2%;
    line-height: 1.75;
    margin-top: 0;
    text-align: justify;
    font-size: 90%;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: #E5E5E5;
}


.stt-interviewer-content{
    padding-right: calc(2% + 25.2px);
}

.stt-candidate-content{
    padding-left: calc(2% + 25.2px);
    margin-left: auto;
}

.filename-display {
    white-space: nowrap; 
    overflow: hidden;  
    text-overflow: ellipsis; 
    max-width: 150px; 
}
