@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Montserrat:wght@400;700&display=swap');

/* Title Text Bold*/
.title-text {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    font-family: 'Montserrat', sans-serif;
}

/* Title Text Bold extra*/
.title-text-huge {
    font-size: 2.1rem;
    color: #333;
    font-family: 'Montserrat', sans-serif;
}

/* Title Text Bold*/
.title-text-light-huge {
    font-size: 2.1rem;
    font-weight: bold;
    color: #3370c5;
    font-family: 'Montserrat', sans-serif;
}

/* Title Text Thin*/
.title-text-thin {
    font-size: 1.8rem;
    color: #333;
    font-family: 'Montserrat', sans-serif;
}

/* Subtitle Text Grey*/
.subtitle-text {
    font-size: 1.5rem;
    color: #666;
    font-family: 'Montserrat', sans-serif;
}

/* Subtitle Text Black*/
.subtitle-text {
    font-size: 1.5rem;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

/* Title Text mini*/
.title-text-mini {
    font-size: 0.8rem;
    color: #333;
    font-family: 'Montserrat', sans-serif;
}

.title-text-mini-bold {
    font-size: 0.8rem;
    font-weight: bold;
    color: #333;
    font-family: 'Montserrat', sans-serif;
}

/* Mini Text alert*/
.mini-secondary-alert {
    font-size: 0.8rem;
    color: #E45F2B;
    font-family: 'Montserrat', sans-serif;
}

/* Normal Text alert*/
.normal-secondary-alert {
    font-size: 0.9rem;
    color: #E45F2B;
    font-family: 'Montserrat', sans-serif;
}

/* Small Grey Text for Annotation*/
.small-grey-text {
    font-size: 0.8rem;
    color: #888;
    font-family: 'Roboto', sans-serif;
}

/* Normal Text */
.normal-text {
    font-size: 1rem;
    color: #444;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Text White Bold*/
.text-white-mini-bold {
    font-size: 0.9rem;
    color: #ffffff;
    line-height: 1.4;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

/* Title Text Thin*/
.normal-text-thin {
    font-size: 1em;
    color: #444;
    line-height: 1.4;
    font-family: 'Montserrat', sans-serif;
}

/* Normal Text Grey*/
.normal-text-light {
    font-size: 1rem;
    color: #555;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Bold Text */
.normal-text-bold {
    font-size: 1rem;
    color: #444;
    font-weight: bold;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Text Small*/
.normal-text-small {
    font-size: 0.9rem;
    color: #444;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Bold Text Small*/
.normal-text-bold-small {
    font-size: 0.9rem;
    color: #444;
    font-weight: 600;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Text Grey small*/
.normal-text-light-small {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Text Mini*/
.normal-text-mini {
    font-size: 0.8rem;
    color: #444;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}


/* Normal Text Mini Gray*/
#normal-text-mini {
    font-size: 0.8rem;
    color: #888;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Bold Text Mini*/
.normal-text-bold-mini {
    font-size: 0.8rem;
    font-weight: bold;
    color: #444;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Bold Text Mini with main theme*/
.normal-text-mini-grey {
    font-size: 0.8rem;
    color: #a6a3a3;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* Normal Bold Text Mini with main theme*/
.normal-text-mini-grey-bold {
    font-size: 0.8rem;
    font-weight: bold;
    color: #a6a3a3;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

.normal-text-small-main {
    font-size: 1rem;
    font-weight: bold;
    color: #3370c5;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
}

/* White Text */
.white-text {
    font-size: 1rem;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
}

/* Message Text*/
.message-text {
    font-size: 1.5rem;
    font-family: Verdana;
}
  