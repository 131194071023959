.main-button-middle {
  background-color: var(--color-primary-dark);
  color: #ffffff;
  width: 220px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
  box-shadow: none;
}

.main-button-middle:hover {
  background-color: var(--color-primary-semidark);
}

.main-button-white-middle {
  background-color: #ffffff;
  color: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  width: 220px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
}

.main-button-white-middle:hover {
  background-color: var(--color-primary-fade);
}

.main-button-small {
  background-color: var(--color-primary-dark);
  color: #ffffff;
  width: 150px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
}

.main-button-small:hover {
  background-color: var(--color-primary-semidark);
}

.main-button-white-small {
  background-color: #ffffff;
  color: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  width: 160px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
}

.main-button-white-small:hover {
  background-color: var(--color-primary-fade);
}

.main-button-semimiddle-thin {
  background-color: var(--color-primary-dark);
  color: #ffffff;
  width: 180px;
  height: 32px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
  box-shadow: none;
}

.main-button-semimiddle-thin:hover {
  background-color: var(--color-primary-semidark);
}

.main-button-long {
  background-color: var(--color-primary-dark);
  color: #ffffff;
  width: 300px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
}

.main-button-long:hover {
  background-color: var(--color-primary-semidark);
}

.main-button-white-long {
  background-color: #ffffff;
  color: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  width: 300px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
}

.main-button-white-long:hover {
  background-color: var(--color-primary-fade);
}

.main-button-mini {
  background-color: var(--color-primary-dark);
  color: #ffffff;
  width: 90px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
}

.main-button-mini:hover {
  background-color: var(--color-primary-semidark);
}

.main-button-white-mini {
  background-color: #ffffff;
  color: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  width: 90px;
  height: 35px;
  border-radius: 15px;
  margin: 15px;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.4s;
}

.main-button-white-mini:hover {
  background-color: var(--color-primary-fade);
}
  