.complete-interview {
    align-items: center;
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;   
    justify-content: center;      
    margin-top: 5%;
  }

  .large-text-container {
    align-items: center;
    display: flex;
    flex-direction: column;   
    justify-content: center;    
    width: 60%;
    height: 150px;
  }

  .complete-interview-image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
  }