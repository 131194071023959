.user-servey {
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;   
    justify-content: center;      
  }
  
  .survey-text-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 40%;
    height: 200px;
    margin-bottom: 1.2%;
    margin-top: 2%;
  }

  .survey-card-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-around;
    width: 60%;
    height: 260px;
    margin-bottom: 1.7%;
  }

  .survey-checkbox-container {
    display: flex;
    flex-direction: column;
    max-width: 600px; 
    flex-wrap: nowrap;
    width: 43%;
    height: 320px;
    margin-bottom: 1.9%;
  }
  
  .survey-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 1%;
  }

  .survey-textfield .MuiOutlinedInput-root {
    border-radius: 12px;
  }
  .survey-textfield .MuiOutlinedInput-root fieldset {
    border-color: rgba(0,0,0,0.23);
  }
  .survey-textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--color-primary-main);
  }
  .survey-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--color-primary-main);
  }
